import classes from "../../../css/deviceStatus.module.css";
import { deviceStatusType } from "../../../../enums/deviceStatus";
import { scheduleResponseMapper } from "../../../../helpers/scheduleResponseMapper";

const ScheduleStatus = ({ device }) => {
  const prepareData = (data) => {
    const splitted = data.split(":")[1];
    if(splitted) {
      return splitted?.replace(/\s/g, "").toUpperCase();
    }

    return data?.replace(/\s/g, "").toUpperCase();
  };

  return (
    <div className={classes["device-status-wrapper"]}>
      {device.schedule ? (
        scheduleResponseMapper(prepareData(device.schedule)) ||
        prepareData(device.schedule)
      ) : (
        <>{deviceStatusType.notApplicable}</>
      )}
    </div>
  );
};

export default ScheduleStatus;
