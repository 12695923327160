import { useEffect } from "react";
import * as signalR from '@microsoft/signalr';
import { links } from "../../enums/links";
import cookieHelper from "../../helpers/cookieHelper";

const useSignalR = (events = {}, url = links.signalRUrl) => {
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        headers: {
          'X-AF-Token': cookieHelper.getAntiForgeryToken(),
        },
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    Object.keys(events).forEach((eventName) => {
      connection.on(eventName, events[eventName]);
    });

    connection.start()
      .then(() => console.log('SignalR connection established'))
      .catch(err => console.error('SignalR connection failed: ', err));

    return () => {
      connection.stop();
      Object.keys(events).forEach((eventName) => {
        connection.off(eventName);
      });
    };
  }, [events, url]);
};

export default useSignalR;